//
//
//
//
//
//
//
//
//
//
//
//

export default {
    name: 'Button',
    props: {
        size: {
            type: String,
            default: null,
        },
        label: {
            type: [String, Function],
            default: null,
        },
        icon: {
            type: String,
            default: null,
        },
        disable: {
            type: Boolean,
            default: false,
        },
    },
    methods: {
        click() {
            this.$emit('click')
        },
    },
}
