//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {  mapMutations, mapActions, mapGetters } from 'vuex'
import { classUpDownConverter } from '~/utils'

import List from '~/components/Common/UI/List_3Col'

export default {
  name: 'TodaysWorld',

  components: { List },

  props: [],

  data() {
    return {
      newsType: 'MARKET_KOSPI',
    }
  },

  computed: {
    ...mapGetters('main', ['todaysworld']),

    board() {
      return this.$store.state.board.newsList[this.newsType]
    },
  },

  created() {
    this.requestTodaysWorld()
  },

  methods: {
    ...mapActions('board', ['GET_LIST']),
    ...mapActions('main', ['GET_TODAYSWORLD']),
    ...mapMutations('main', ['SET_MARKET_INDEX']),

    requestTodaysWorld() {
      const params = {
        body: '',
      }
      this.GET_TODAYSWORLD(params)
    },

    getList() {
      const params = {
        newsType: this.newsType,
        pageNo: 1,
        countByPage: 5,
      }
      this.GET_LIST(params)
    },

    updown(value) {
        return classUpDownConverter(value)
    }
  },
}
