//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState, mapMutations, mapActions, mapGetters } from 'vuex'

import dayjs from 'dayjs'
import List from '~/components/Common/UI/List_3Col_Rate'
import api from '~/api/'

export default {
    name: 'IncreaseThemeRankingWeek',

    components: { List },

    props: [],

    data() {
        return {
            newsType: 'MARKET_KOSPI',
            curTab: 2, // 일간,주간,월간 순으로 수정 0->2
            now: dayjs(),
            pickedDate: '', // 날짜 선택값
        }
    },
    computed: {
        ...mapGetters('main', ['toptheme', 'topthemeRecentDate']),
        ...mapState('search', ['selectedDate']),
        board() {
            return this.$store.state.board.newsList[this.newsType]
        },
        week() {
            /* 
                220513 meej 테마 상승률 기간 수정
                22.05.31 suye 상승률 endDate 전일자 
            */
            const today = new Date()
            const day = new Date(today).getDate()

            const today2 = new Date()
            const day2 = new Date(today).getDate()

            today.setDate(day - 7)
            const startDate = dayjs(today).format('YYYY-MM-DD')

            today2.setDate(day2 - 1)
            const endDate = dayjs(today2).format('YYYY-MM-DD')

            return `${startDate} ~ ${endDate}`
        },

        month() {
            /* 
                220513 meej 테마 상승률 기간 수정
                22.05.31 suye 상승률 endDate 전일자 
            */
            const today = new Date()
            const day = new Date(today).getMonth()

            const today2 = new Date()
            const day2 = new Date(today).getDate()

            today.setMonth(day - 1)
            const startDate = dayjs(today).format('YYYY-MM-DD')

            today2.setDate(day2 - 1)
            const endDate = dayjs(today2).format('YYYY-MM-DD')

            return `${startDate} ~ ${endDate}`
        },

        // 22.05.19 suye 메인 탭 일간상승률 추가 (전일자)
        day() {
            let startDate = this.stringDateFormat(this.yesterday(1))

            if (this.pickedDate) {
                startDate = dayjs(this.pickedDate).format('YYYY-MM-DD')
            }

            return `${startDate}`
        },
    },

    watch: {
        curTab() {
            this.requestToptheme()
        },
        pickedDate() {
            this.requestToptheme()
        },
    },

    mounted() {
        this.requestToptheme()
    },

    methods: {
        ...mapActions('board', ['GET_LIST']),
        ...mapActions('main', ['GET_TOPTHEME']),

        // 22.05.20 suye 주간,월간,일간상승률
        requestToptheme() {
            let type = ''
            let day = ''
            // let count = ''
            let params = {}
            if (this.curTab === 0) {
                type = 'W'
                // count = 10
                params = {
                    type,
                    // count
                }
                this.pickedDate = ''
            } else if (this.curTab === 1) {
                type = 'M'
                // count = 10
                params = {
                    type,
                    // count
                }
                this.pickedDate = ''
            } else if (this.curTab === 2) {
                type = 'D'
                // count = 10
                if (!this.pickedDate) {
                    // day = this.yesterday(1)
                    day = '00000000'
                }
                if (this.pickedDate) {
                    day = dayjs(this.pickedDate).format('YYYYMMDD')
                }
                params = {
                    type,
                    day,
                    // count
                }
            }

            this.GET_TOPTHEME(params)
        },

        getList() {
            const params = {
                newsType: this.newsType,
                pageNo: 1,
                countByPage: 5,
            }
            this.GET_LIST(params)
        },

        // 일간상승률 전일자 (num=일수)
        yesterday(num) {
            const today = new Date()
            today.setDate(today.getDate() - num)
            const startDate = dayjs(today).format('YYYYMMDD')

            return startDate.toString()
        },

        // 일자 포맷 yyyyMMdd -> yyyy-MM-dd
        stringDateFormat(string) {
            if (string) {
                if (string !== null && string.length === 8) {
                    const year = string.substring(0, 4)
                    const month = string.substring(4, 6)
                    const day = string.substring(6, 8)
                    const output = year + '-' + month + '-' + day
                    return output
                }
            } else {
                return ''
            }
        },

        onClickItem(id) {
            this.curId = id
        },

        onClickWeek() {
            this.curTab = 0
        },
        onClickMonth() {
            this.curTab = 1
        },
        onClickDay() {
            this.curTab = 2
        },
    },
}
