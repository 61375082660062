//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState, mapMutations, mapActions, mapGetters } from 'vuex'
import dayjs from 'dayjs'
import Button from '../../../../infostock-admin/src/components/common/Button.vue'
import api from '~/api/' // server
import { DAY_NAMES, DEFAULT_DATE_FORMAT } from '~/constants' // server

export default {
  
    name: 'StockCalendar',
    components: { Button },
    data() {
        return {
            now: dayjs(),
            scheduleItems: null,
            DEFAULT_DATE_FORMAT,
            DAY_NAMES,
            scheduleItems_all: false, 
            cnt: 0,
            checkIfMore: false, // 더보기용 n개 체크 
        }
    },
    computed: {
        weeks() {
            const _weeks = []

            const startDate = this.now.startOf('week')
            const endDate = this.now.endOf('week').add(1, 'week') // 2주

            const diff = endDate.diff(startDate, 'week')

            let _date = startDate.clone()

            for (let i = 0; i <= diff; i++) {
                const dates = []

                for (let j = 0; j < 7; j++) {
                    dates.push(_date.day(j).format(this.DEFAULT_DATE_FORMAT))
                }

                _weeks.push(dates)

                _date = _date.add(1, 'week')
            }

            return _weeks
        },
    },

    created() {
        this.getSchedule()
    },

    methods: {
        ...mapActions('main', ['GET_CALENDAR_INFO']),

        async getSchedule() {
            // TODO : _suki 추후 확인 필요
            const startDate = this.now.startOf('week')
            const endDate = this.now.endOf('week').add(1, 'week') // 2주
            const params = {
                newsType: 'EVENT_ALL',
                startDate: startDate.format('YYYYMMDD'),
                endDate: endDate.format('YYYYMMDD'),
                condition: '',
                noPermission: 1,
            }

            // this.scheduleItems = (
            //     await api.getPeriodSchedule(params)
            // ).data.items

            await api.getPeriodSchedule(params).then((res)=>{
                if (res && res.success){
                    this.scheduleItems = res.data.items
                } else {
                    alert('main_page stockCalender err')
                }
            }).catch((err)=>{
                console.warn("main_page stockCalender -> ", err)
                alert(err)
            })
            // console.warn('this.scheduleItems: ', this.scheduleItems)
            
            // 길이
            const arrayLen = []
            this.scheduleItems.forEach(e => {
                    // console.log('e', e.length)
                    arrayLen.push(e.length)
                    // console.log('arrayLen: ', arrayLen)
                    this.cnt = Math.max(...arrayLen)
                    // console.log('cnt: ', this.cnt)
            });
            if(this.cnt > 5) { // 일자별 갯수가 5개 초과하면 true
                this.checkIfMore = true;
            } else {
                this.checkIfMore = false;
            }

        },
        dateFormatter(value) {
            const dd = parseInt(value.slice(-2))

            if (dd === 1) {
                const monthInt = parseInt(value.slice(4, 6))
                return `${monthInt}/${dd}`
            }

            return dd
        },
        onClickCalItem(item) {
            const nowMM = this.now.format('YYYYMMDD').slice(4, 6)
            const eventDateMM = item.eventDate.slice(4, 6)

            // console.log('eventDate=', eventDateMM)
            // console.log('nowMM=', nowMM)

            const params = { id: item.id }

            if (eventDateMM !== nowMM) {
                params.eventDate = item.eventDate
            }

            this.GET_CALENDAR_INFO(params)

            this.$router.push({
                name: 'EventNews-Month',
            })
        },
    },
}
